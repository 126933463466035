.wrapperRarius {
    border-radius: 16px;
}

.noListPadind ul{
    padding-left: 0;
}

@media screen and (min-width: 768px) {
    .wrapperRarius {
        border-radius: 32px;
    }

    .noListPading ul{
        padding-left: 0;
    }

}